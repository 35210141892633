import { Vue, Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component/lib/util';
import template from './GenerationEchantillons.Template.vue';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import Confirm from '@/components/Confirm.vue';
import { ApiService } from '@/services/base/ApiService';
import { TemplateOperation, Societe, Operation } from '@/models';
import GenerationEchantillonsValidator from './GenerationEchantillonsValidator';
import CeeDatePicker from '@/components/CeeDatePicker.vue';
import { EchantillonCriteria } from '../EchantillonCriteria.model';
import { AxiosResponse } from 'axios';
import { Result, ValeurReferentielle, DataTableHeader } from '@/shared/models';
import CeeAutocomplete from '@/components/CeeAutocomplete.vue';
import ApiHelper from '@/services/ApiHelper';
import { DateHelper } from '../../../../shared/helpers';

@Component({
    ...template,
    name: 'GenerationEchantillons',
    components: {
        CeeDatePicker,
        Confirm,
        CeeAutocomplete
    }
})
export default class GenerationEchantillons extends mixins(GenerationEchantillonsValidator, GrilleMixin) {
    public $refs!: Vue['$refs'] & {
        form: HTMLFormElement,
        confirm: {
            open: ((title: string | null, message: string | null, options: { color?: string, width?: number, zIndex?: number }) => Promise<boolean>),
        },
    };

    private loading: boolean = false;
    private loadingOperations: boolean = false;
    private loadingOperationsPreview: boolean = false;

    private criteresAvances: EchantillonCriteria = new EchantillonCriteria();
    private listeOperationSoumisControle: TemplateOperation[] = [];
    private listeOrganismeControle: ValeurReferentielle[] = [];
    private operationsEchantillon: Operation[] = [];

    private headers: DataTableHeader[] = [
        { text: 'Référence interne\n de l\'opération', value: 'numero', align: 'center' },
        { text: 'Raison Sociale\n Bénéficiaire', value: 'raisonSocialeBeneficiaire', align: 'center' },
        { text: 'Code de\n l\'opération', value: 'code', align: 'center' },
        { text: 'Volume CEE\n Classique\n (MWh cumc)', value: 'volumeCeeClassiqueEnMWhCumac', align: 'center' },
        { text: 'Volume CEE\n Précarité\n (MWh cumc)', value: 'volumeCeePrecariteEnMWhCumac', align: 'center' },
        { text: 'Date\n d\'engagement\n des travaux', value: 'dateDebutTravaux', align: 'center' },
        { text: 'Date \nd\'achèvement\n des travaux', value: 'dateFinTravaux', align: 'center' },
        { text: 'Statut de\n l\'opération', value: 'statutOperation', align: 'center' },
        { text: 'Statut \npolitique de \ncontrôle', value: 'statutPolitiqueControle', align: 'center' },
        { text: 'Statut de\n l\'échantillon', value: 'statutEchantillon', align: 'center' },
    ];

    private get operationLoaded(): boolean{
        return this.operationsEchantillon && this.operationsEchantillon.length > 0;
    }

    public get getVolumeTotal(): number {
        if (this.operationsEchantillon.length > 0) {
            return this.criteresAvances.operationsSelected.reduce((partialSum, a) => partialSum + (a.volumeCeeClassique + a.volumeCeePrecarite), 0) / 1000;
        }

        return 0;
    }


    public created() {
        this.getData();
    }

    // Override du getData de GrilleMixin.
    public getData() {
        this.loading = true;
        this.getAllTemplateSoumisControle();
        this.getAllOrganismeControle();
    }

    public getAllTemplateSoumisControle(): void {
        this.loadingOperations = true;
        this.$http.get('/templateOperations/listOffresSoumisControle')
            .then((result) => {
                const { data: { data: responseData } } = result as ({ data: { data: TemplateOperation[], isError: boolean, messages: any[] } });
                if (!!responseData && responseData.length > 0) {
                    this.listeOperationSoumisControle = responseData;
                }
            }).finally(() => this.loadingOperations = false);
    }

    public getAllOrganismeControle(): void {
        this.$http.get('/organismeControle/obtenirTous')
            .then((result) => {
                const { data: { data: responseData } } = result as ({ data: { data: TemplateOperation[], isError: boolean, messages: any[] } });
                if (!!responseData && responseData.length > 0) {
                    this.listeOrganismeControle = responseData;
                }
            }).finally(() => this.loading = false);

    }

    public creerEchantillon(): void {
        if (this.$refs.form.validate()) {
            this.loading = true;
            this.criteresAvances.operationsIdSelected = this.criteresAvances.operationsSelected.map(x => x.id);
            this.$http.post('/echantillon/creerEchantillon', this.criteresAvances)
                .then((result) => {
                    const { data: { data: responseData } } = result as ({ data: { data: TemplateOperation[], isError: boolean, messages: any[] } });
                    this.previsualiserEchantillon();
                }).catch().finally(() => this.loading = false);
        }
    }

    /**
    * Recherche des raisons sociales correspondant à la saisie.
    * @param saisie Raison sociale saisie.
    */
    public rechercheRaisonSociale(saisie: string): Promise<string[]> {
        const apiRaisonSociale = new ApiService<string>(`/societe/RechercherSociete/${saisie}//`);
        return new Promise<string[]>((resolve, reject) => {
            apiRaisonSociale.getAll().then((reponse: AxiosResponse<Result<string[]>>) => {
                resolve(reponse.data.data);
            }).catch((error: any) => reject(error));
        });
    }


    /**
    * Récupère le texte d'un membre utilisateur pour la sélection.
    * @param utilisateur L'utilisateur.
    */
    public getTexteSociete(societe: Societe): string {
        return `${societe.raisonSociale} (${societe.siret})`;
    }

    /**
     * Generation recapitulatif.
     * @memberof Reporting
     */
    public genererRecapitulatif(): void {
        if (this.$refs.form.validate()) {
            this.loading = true;
            this.criteresAvances.operationsIdSelected = this.criteresAvances.operationsSelected.map(x => x.id);
            this.$http.post('/echantillon/exportEchantillonExcel', this.criteresAvances)
                .then((reponse) => {
                   ApiHelper.createAndDownloadBlobFile(reponse.data);
                }).catch().finally(() => this.loading = false);
        }
    }

    public previsualiserEchantillon(): void {
        if (this.$refs.form.validate()) {
            this.loading = true;
            this.$http.post('/echantillon/obtenirOperationsByCriteriaEchantillon', this.criteresAvances)
                .then((reponse) => {
                    this.operationsEchantillon = reponse.data.data;
                    this.handleToggleSelection(true);
                }).catch().finally(() => this.loading = false);
        }
    }

    private getDateFormated(date: Date) {
        return DateHelper.format(date);
    }

    private handleToggleSelection(selectAll: boolean) {
        if (selectAll) {
            this.criteresAvances.operationsSelected = this.operationsEchantillon;
        }
        else {
            this.criteresAvances.operationsSelected = [];
        }
    }
}
