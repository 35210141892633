import { ValidationMixin } from '@/shared/mixins';
import { Component } from 'vue-property-decorator';

@Component({})
export default class GenerationEchantillonsValidator extends ValidationMixin {

    public dateRules = [
        (v: string | any) => this.required(v) || 'La date est obligatoire',
    ];

    protected nomDepotEchantillonRules = [
        (v: string | any) => this.required(v) || 'Le nom de l\'échantillon est obligatoire',
    ];

    protected templateOperationRules = [
        (v: string | any) => v.length > 0 || 'Selectionnez au moins une opération',       
    ];
}
